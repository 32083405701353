import { Link } from 'gatsby'
import * as React from 'react'
import { Col, Row } from 'react-bootstrap'
import { IImage } from '../../types/Image'
import { LangKey } from '../../types/Languages'
import * as styles from './Citation.module.scss'
import ColoredBox from './ColoredBox'
import ContentImage from './ContentImage'

const Citation: React.FunctionComponent<CitationProps> = (props: CitationProps) => {
    const { alignText, image, citation, author, company, to, pdftColor, language } = props

    const classes = ` ${alignText === 'right' ? 'flex-row-reverse' : ''} ${styles.quote} `

    let citationBody = (
        <Row className={classes}>
            <Col sm={8}>
                <cite>
                    <p className={styles.citeBody}>{QuoteText(citation, language)}</p>
                </cite>
                <footer className={styles.citeFooter}>
                    {author}
                    {company && (
                        <>
                            <br />
                            {company}
                        </>
                    )}
                </footer>
            </Col>
            {image && (
                <Col sm={4} className={styles.imageContainer}>
                    <ContentImage fluid={true} image={image} />
                </Col>
            )}
        </Row>
    )

    if (to) {
        citationBody = (
            <Link className={styles.citationLink} to={to}>
                {citationBody}
            </Link>
        )
    }

    if (pdftColor) {
        return (
            <ColoredBox fullWidth={true} {...props}>
                <blockquote className={styles.citation}>{citationBody}</blockquote>
            </ColoredBox>
        )
    }

    return <blockquote className={styles.citation}>{citationBody}</blockquote>
}

interface CitationProps {
    image?: IImage
    author: string
    citation: string
    company?: string
    language?: LangKey // for quotation marks
    alignText: 'left' | 'right'
    to?: string
    pdftColor?: string
}

export default Citation

/**
 * Adds quotation marks to a citation
 *
 * @param quote the citation text to be quoted
 * @param lang the language, determines the quotation marks
 * @returns the citation text surrounded by quotation marks
 */
export const QuoteText = (quote: string, lang?: LangKey): string => {
    switch (lang) {
        case 'de':
            return `„${quote}“`
        case 'fr':
            return `« ${quote} »`
    }
    return `“${quote}”`
}
