import { useStaticQuery, graphql } from 'gatsby'
import * as React from 'react'
const PdfToolsAddress: React.FunctionComponent<PdfToolsAddressProps> = (props: PdfToolsAddressProps) => {
    const data = useStaticQuery(graphql`
        {
            directusDomicile {
                address
                city
                country
                name
                zip
                phonenumber
                phonenumber_title
            }
        }
    `)

    const domicile = data.directusDomicile || {}

    return (
        <address>
            <div>
                <strong>{domicile.name}</strong>
            </div>
            {domicile.address}
            <br />
            {domicile.zip} {domicile.city}, {domicile.country} <br />
            <br />
            <a href={'tel:' + domicile.phonenumber}>{domicile.phonenumber_title}</a>
            <br />
            <br />
            <sup>© 2002-{new Date().getFullYear()} PDF Tools AG</sup>
        </address>
    )
}

export default PdfToolsAddress

interface PdfToolsAddressProps {}
