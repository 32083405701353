import * as React from 'react'
import { Button } from 'react-bootstrap'
import { logout } from '../../services/auth.js'
import Translation from '../../types/Translation'
import axios from '../../utils/axios'
import withTranslations from '../hoc/withTranslations'
import * as styles from './Logout.module.scss'

const Logout: React.FunctionComponent<LogoutProps> = (props: LogoutProps) => {
    const { t } = props

    const handleLogout = React.useCallback(
        async (event: any) => {
            event.preventDefault()

            const url = '/api/v1.0/Users/Authenticate'

            try {
                axios.delete(url) // on purpose no await (no need for an await in this case)

                logout()

                if (props.onLogout) {
                    props.onLogout()
                }
            } catch (e) {
                console.log(e)
            }
        },
        [props],
    )

    let btn = (
        <Button size="sm" variant="outline-primary" className="mb-0" onClick={handleLogout}>
            {t('template', 'logout.btn.text')}
        </Button>
    )

    if (props.mobile) {
        btn = (
            <Button variant="link" className={styles.logoutButtonMobile} onClick={handleLogout}>
                {t('template', 'logout.btn.text')}
            </Button>
        )
    }

    return btn
}

export default withTranslations(Logout)

interface LogoutProps extends Translation {
    onLogout: () => void
    mobile?: boolean
}
