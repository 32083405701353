import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { getToken, isLoggedIn } from '../services/auth.js'

const get = async (url: string, config: AxiosRequestConfig | undefined = undefined) => {
    return axios.get(url, completeConfig(config))
}

const post = async (url: string, data: any, config: AxiosRequestConfig | undefined = undefined) => {
    return axios.post(url, data, completeConfig(config))
}

const put = async (url: string, data: any, config: AxiosRequestConfig | undefined = undefined) => {
    return axios.put(url, data, completeConfig(config))
}

const httpDelete = async (url: string, config: AxiosRequestConfig | undefined = undefined) => {
    return axios.delete(url, completeConfig(config))
}

const completeConfig = (config: any) => {
    let finalConfig = config

    if (isLoggedIn()) {
        finalConfig = addAuthoriztionHeader(config)
    }

    return finalConfig
}

const addAuthoriztionHeader = (config: any = { headers: {} }) => {
    const token = getToken()

    const finalConfig = {
        ...config,
        headers: {
            ...config.headers,
            Authorization: `Bearer ${token}`,
        },
    }

    return finalConfig
}

const axiosUtil = {
    post: post,
    get: get,
    delete: httpDelete,
    put: put,
    spread: axios.spread,
    all: axios.all,
}

/* Polyfill Promise.allSettled */
export const allSettled = async (promises: TAxiosPromise[]) => {
    return Promise.all(
        promises.map((p) =>
            Promise.resolve(p).then(
                (value) => ({
                    status: 'fulfilled',
                    value,
                }),
                (reason) => ({
                    status: 'rejected',
                    reason,
                }),
            ),
        ),
    )
}

export type TAxiosPromise = Promise<AxiosResponse<any>>

export default axiosUtil
