import { IEvalFormData } from '../types/Product'

export const saveEvalForm = (productCode: string, platformCode: string, productName: string, evalType: string) => {
    if (!productCode || !platformCode) {
        return false
    }

    sessionStorage.setItem('product_code', productCode)
    sessionStorage.setItem('platform_code', platformCode)
    sessionStorage.setItem('product_name', productName)
    sessionStorage.setItem('eval_type', evalType)

    return true
}

export const readEvalForm = () => {
    const platformCode = sessionStorage.getItem('platform_code')
    const productCode = sessionStorage.getItem('product_code')
    const productName = sessionStorage.getItem('product_name')
    const evalType = sessionStorage.getItem('eval_type')

    if (!productCode || !platformCode || !productName) {
        return null
    }

    const formData: IEvalFormData = {
        productCode,
        platformCode,
        productName,
        evalType: evalType || undefined,
    }

    return formData
}
