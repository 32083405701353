import React from 'react'
import { Subtract } from 'utility-types'
import { ITranslationContext, TranslationContext } from '../../context/TranslationContext'
import Translation from '../../types/Translation'

import { translate, ITranslationCategory } from '../../helper/TranslationHelper'

const createT = (table: ITranslationCategory) => (category: string, key: string) => translate(category, key, table)

const withTranslations = <P extends Translation>(
    Component: React.ComponentType<P>,
): React.Component<Subtract<P, Translation>> => {
    class TranslationHoc extends React.Component<Subtract<P, Translation>> {
        t: (category: string, key: string) => void

        // TODO: move away from legacy context (https://reactjs.org/docs/legacy-context.html)
        constructor(props: any, context: ITranslationContext) {
            super(props, context)

            const translationTable = this.context.translationTable

            const t = createT(translationTable)

            this.t = t
            this.t = this.t.bind(this)
        }

        render() {
            return <Component {...(this.props as P)} t={this.t} />
        }
    }

    TranslationHoc.contextType = TranslationContext

    return TranslationHoc
}

export default withTranslations
