import React from 'react'
import { formatDate } from '../../../helper/DateHelper'
import { LangKey } from '../../../types/Languages'
import { IKit } from '../../../types/LicenseKit'
import ContentButton from '../ContentButton'
import withTranslations from '../../hoc/withTranslations'
import Translation from '../../../types/Translation'
import NoDownloadTooltip from './NoDownloadTooltip'

class ProductKitTableRow extends React.Component<ProductKitTableRowProps> {
    render() {
        const { t, language, kit, maintenanceActiveUntil } = this.props

        const maintenanceActive = !maintenanceActiveUntil || new Date(maintenanceActiveUntil) >= new Date(kit.fileDate)

        return (
            <tr>
                <td>{kit.description}</td>
                <td>{kit.fullVersion}</td>
                <td>{kit.fileSize}</td>
                <td>{formatDate(kit.fileDate, language)}</td>
                <td style={{ width: '15%' }}>
                    {maintenanceActive ? (
                        <ContentButton
                            pdftColor="green"
                            size="sm"
                            block={true}
                            onClick={this.onDownloadButtonClick}
                            className="my-0 text-nowrap"
                        >
                            <span className="pti-download" />
                            {t('template', 'general.btn.download')}
                        </ContentButton>
                    ) : (
                        <NoDownloadTooltip />
                    )}
                </td>
            </tr>
        )
    }

    onDownloadButtonClick = () => {
        const { onDownloadKit, kit } = this.props
        onDownloadKit(kit)
    }
}

export default withTranslations(ProductKitTableRow)

interface ProductKitTableRowProps extends Translation {
    language: LangKey
    kit: IKit
    maintenanceActiveUntil?: string
    onDownloadKit: (kit: IKit) => void
}
