import * as React from 'react'
import { isLoggedIn } from '../../../services/auth.js'
import { NavMenusIndexKey } from '../../../types/Navigation.js'
import PageContext from '../../../types/PageContext'
import AnyLink from '../../AnyLink'
import * as styles from './MainNavigation.module.scss'
import MenuDropdown from './MenuDropdown'
import MenuTab from './MenuTab'
import MenuTabTitle from './MenuTabTitle'

export default class MainNavigation extends React.Component<MainNavigationProps, MainNavigationState> {
    state: MainNavigationState = {
        activeMenuRef: '',
    }

    toggleMenuVisibility = (ref: any) => {
        const activeMenuRef = this.state.activeMenuRef

        if (activeMenuRef && activeMenuRef !== ref) {
            activeMenuRef.classList.toggle('hidden')
        }

        if (ref) {
            if (ref.classList.contains('hidden')) {
                ref.classList.remove('hidden')
            }
        }
    }

    hide = (ref: any) => {
        ref.classList.add('hidden')
    }

    toggleActiveMenu = (ref = null) => {
        this.setState({ activeMenuRef: ref })
    }

    public render() {
        const { pageContext } = this.props
        const { navigation } = pageContext

        const navMenus: any = []
        const loggedIn = isLoggedIn()

        if (navigation) {
            navMenus.push(
                <li style={{ marginRight: '30px', paddingTop: '7px' }}>
                    <AnyLink to="https://www.pdf-tools.com/" target="_self">
                        &#x1F890; exit My PDF Tools
                    </AnyLink>
                </li>,
            )

            Object.keys(navigation).forEach((navMenuKey) => {
                const navMenu = navigation[navMenuKey as NavMenusIndexKey]

                // if (navMenuKey === 'mypdftools' && !loggedIn) {
                //     return null
                // }

                if (navMenuKey !== 'mypdftools') {
                    // disable all nav entries except mypdftools
                    return null
                }

                navMenus.push(
                    <MenuDropdown
                        title={navMenu.title}
                        onDropdownChange={this.toggleActiveMenu}
                        onHover={this.toggleMenuVisibility}
                        hide={this.hide}
                        key={navMenuKey}
                        menuEntryLink={navMenu.menuEntryLink}
                    >
                        {loggedIn && <MenuTab navMenu={navMenu} hasCareer={false} pageContext={pageContext} />}
                        {!loggedIn && <MenuTabTitle title="Please log in" subTitle="to the My PDF Tools Portal" />}
                    </MenuDropdown>,
                )
            })
        } else {
            return (
                <nav>
                    <ul>
                        <li>Navigation is not defined</li>
                    </ul>
                </nav>
            )
        }

        return (
            <nav>
                <ul className={`${styles.mainNavigation} list-inline text-right position-relative`}>{navMenus}</ul>
            </nav>
        )
    }
}

interface MainNavigationProps {
    pageContext: PageContext<any>
}

interface MainNavigationState {
    activeMenuRef: any
}
