import * as React from 'react'
import { Col } from 'react-bootstrap'
import * as styles from './MenuTabTitle.module.scss'

const MenuTabTitle: React.FunctionComponent<MenuTabTitleProps> = (props: MenuTabTitleProps) => {
    const { title, subTitle } = props

    const menuSubTitle = <span className={styles.subTitle}>{subTitle}</span>

    return (
        <Col className={styles.titleWrapper}>
            <span className={styles.title}>{title}</span> {subTitle && menuSubTitle}
        </Col>
    )
}

interface MenuTabTitleProps {
    title: string
    subTitle?: string
}

export default MenuTabTitle
