import { IFilterOption } from '../types/Common'
import { ICountries } from '../types/Country'

const postifyData = (formData: any) => {
    Object.keys(formData).forEach((key) => {
        const val = formData[key]

        if (typeof val === 'object' && val !== null) {
            formData[key] = val.value || ''
        }
    })

    return formData
}

export const findCountryOption = (country: string, countryOptions: IFilterOption[], countries?: ICountries) => {
    let index = null

    index = countryOptions.findIndex((filterOption) => filterOption.value === country)

    if (index !== -1) {
        return countryOptions[index]
    }

    index = countryOptions.findIndex((filterOption) => filterOption.value === country)

    if (index !== -1) {
        return countryOptions[index]
    }

    index = countryOptions.findIndex((filterOption) => filterOption.value === country)

    if (index !== -1) {
        return countryOptions[index]
    }

    return null
}

export default postifyData
