/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Location } from '@reach/router'
import queryString from 'query-string'
import React from 'react'

const withLocation = <P extends Object>(ComponentToWrap: React.ComponentType<P>) => (props: any) => (
    <Location>
        {({ location, navigate }) => (
            <ComponentToWrap
                {...props}
                location={location}
                navigate={navigate}
                search={location.search ? queryString.parse(location.search) : {}}
            />
        )}
    </Location>
)

export default withLocation
