import * as React from 'react'
import { Table } from 'react-bootstrap'
import { LangKey } from '../../../types/Languages'
import { IKit } from '../../../types/LicenseKit'
import Translation from '../../../types/Translation'
import withTranslations from '../../hoc/withTranslations'
import * as styles from './ProductKitTable.module.scss'
import ProductKitTableRow from './ProductKitTableRow'

class ProductKitTable extends React.Component<ProductKitTableProps, ProductKitTableState> {
    render() {
        const { t, language, productKits, maintenanceActiveUntil, onDownloadKit } = this.props

        return (
            <Table striped={true} className={styles.productKitTable}>
                <thead>
                    <tr>
                        <th>{t('template', 'kits.table.head.productkit')}</th>
                        <th style={{ width: '15%' }}>Version</th>
                        <th style={{ width: '15%' }}>{t('template', 'kits.table.head.filesize')}</th>
                        <th style={{ width: '30%' }} colSpan={2}>
                            {t('template', 'kits.table.head.date')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {productKits.map((kit) => (
                        <ProductKitTableRow
                            key={kit.id + kit.uri}
                            language={language}
                            kit={kit}
                            maintenanceActiveUntil={maintenanceActiveUntil}
                            onDownloadKit={onDownloadKit}
                        />
                    ))}
                </tbody>
            </Table>
        )
    }
}

export default withTranslations(ProductKitTable)

interface ProductKitTableProps extends Translation {
    language: LangKey
    productKits: IKit[]
    maintenanceActiveUntil?: string
    onDownloadKit: (kit: IKit) => void
}

interface ProductKitTableState {}
