import * as React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import Translation from '../../../types/Translation'
import withTranslations from '../../hoc/withTranslations'

const NoDownloadTooltip: React.FunctionComponent<NoDowloadTooltipProps> = (props: NoDowloadTooltipProps) => {
    const placement = 'top'

    return (
        <OverlayTrigger
            key={placement}
            placement={placement}
            overlay={<Tooltip id={`tooltip-${placement}`}>{props.t('template', 'general.info.maintenance.reneval')}</Tooltip>}
        >
            <div className={'text-center'}>
                <i className={'pti-info-twirl-circle info-icon-bigger'} />
            </div>
        </OverlayTrigger>
    )
}

export default withTranslations(NoDownloadTooltip)

interface NoDowloadTooltipProps extends Translation {}
