import { IFilterOption } from './Common'

export type LangKey = 'en' | 'de' | 'fr'

// TODO: move data out of types
export const languageOptions = {
    en: [
        { label: 'English', value: 'en' },
        { label: 'German', value: 'de' },
        { label: 'French', value: 'fr' },
    ],
    de: [
        { label: 'Englisch', value: 'en' },
        { label: 'Deutsch', value: 'de' },
        { label: 'Französisch', value: 'fr' },
    ],
    fr: [
        { label: 'Anglais', value: 'en' },
        { label: 'Allemand', value: 'de' },
        { label: 'Français', value: 'fr' },
    ],
}

export const allLanguageKeys: LangKey[] = ['en', 'de', 'fr']

export interface ILanguageOptions {
    en: IFilterOption[]
    de: IFilterOption[]
    fr: IFilterOption[]
}
