import * as React from 'react'
import { Button, Overlay, Popover } from 'react-bootstrap'
import { handleLogin } from '../../services/auth.js'
import Translation from '../../types/Translation.js'
import withTranslations from '../hoc/withTranslations'
import LoginForm from './form/LoginForm'
import * as styles from './LoginByButton.module.scss'

class LoginByButton extends React.Component<LoginByButtonProps, LoginByButtonState> {
    state = {
        show: false,
        lastLoginFormState: null,
    }

    target: any = React.createRef()

    toggleShow = () => {
        this.setState({ show: !this.state.show })
    }

    handleClose = () => {
        this.setState({ show: false })
    }

    handleFormClose = (formState: any) => {
        const newFormState = {
            ...formState,
        }

        if (formState.forgotPassword) {
            newFormState.forgotPassword = false
        }

        this.setState({ lastLoginFormState: newFormState })
    }

    handleLoginFromForm = (result: any) => {
        if (result) {
            const { token, fullName } = result

            const loginSuccess = handleLogin(token, fullName)

            if (loginSuccess && this.props.onLogin) {
                this.props.onLogin(fullName)
            }
        }
    }

    render() {
        return (
            <div className={styles.login}>
                <Button size="sm" variant="outline-primary" className="mb-0" onClick={this.toggleShow} ref={this.target}>
                    {this.props.t('form', 'general.button.signin')}
                </Button>

                <Overlay
                    target={this.target.current}
                    placement="bottom"
                    rootClose={true}
                    show={this.state.show}
                    onHide={this.handleClose}
                    rootCloseEvent="mousedown"
                >
                    <Popover id="popover-login">
                        <Popover.Content bsPrefix="account">
                            <LoginForm
                                onFormClose={this.handleFormClose}
                                onSubmit={this.handleLoginFromForm}
                                lastLoginFormState={this.state.lastLoginFormState}
                            />
                        </Popover.Content>
                    </Popover>
                </Overlay>
            </div>
        )
    }
}

export default withTranslations(LoginByButton)

interface LoginByButtonProps extends Translation {
    onLogin: (fullName: string) => void
}

interface LoginByButtonState {
    show: boolean
    lastLoginFormState: any
}
