import * as React from 'react'
import * as styles from './MainContent.module.scss'

const MainContent: React.FunctionComponent<MainContentProps> = ({ children }: MainContentProps) => (
    <main className={styles.content}>{children}</main>
)

export default MainContent

interface MainContentProps {
    children: React.ReactNode
}
