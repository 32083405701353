import * as React from 'react'
import { Button, ButtonProps, OverlayTrigger, Tooltip } from 'react-bootstrap'
import AnyLinkButton from './AnyLinkButton'

const ContentButton: React.FunctionComponent<ContentButtonProps> = (props: ContentButtonProps) => {
    const { to, tooltip, pdftColor, upperCase, children, className, block, fullheight } = props

    let button: JSX.Element
    if (to) {
        button = (
            <AnyLinkButton to={to} {...props}>
                {children}
            </AnyLinkButton>
        )
    } else {
        let classes = 'mr-2'
        if (pdftColor) {
            classes += ' ' + pdftColor
        }
        if (upperCase) {
            classes += ' btn-upper'
        }
        if (className) {
            classes += ' ' + className
        }
        if (block && !fullheight) {
            classes += ' btn-block'
        }
        if (fullheight) {
            classes += ' btn-fullheight'
        }

        const { active, disabled, href, size, type, variant, autoFocus, name, value, onClick } = props

        button = (
            <Button
                className={classes || undefined}
                active={active}
                disabled={disabled}
                href={href}
                size={size}
                type={type}
                variant={variant}
                autoFocus={autoFocus}
                name={name}
                value={value}
                onClick={onClick}
            >
                {children}
            </Button>
        )
    }

    if (tooltip) {
        return (
            <OverlayTrigger key="btn-trigger" placement="top" overlay={<Tooltip id="tooltip-btn">{tooltip}</Tooltip>}>
                {button}
            </OverlayTrigger>
        )
    } else {
        return button
    }
}

ContentButton.defaultProps = {
    variant: 'primary',
    disabled: false,
    upperCase: true,
}

export default ContentButton

interface ContentButtonProps
    extends ButtonProps,
        Omit<React.HTMLProps<HTMLButtonElement>, 'as' | 'href' | 'ref' | 'size' | 'type'> {
    to?: string
    tooltip?: string
    pdftColor?: string
    upperCase?: boolean
    fullheight?: boolean
}
