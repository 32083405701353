import * as React from 'react'
import { Element as DElment } from 'domhandler'
import parse, { attributesToProps, domToReact, HTMLReactParserOptions } from 'html-react-parser'
import AnyLink from '../AnyLink'

const RenderHtml: React.FunctionComponent<RenderHtmlProps> = (props: RenderHtmlProps) => {
    const { html, ...divProps } = props

    // check if there are any links in the html string
    if (REGEX_A_TAG.test(html)) {
        return <div {...divProps}>{parse(html, parserOptions)}</div>
    }
    return <div dangerouslySetInnerHTML={{ __html: html }} {...divProps} />
}

export interface RenderHtmlProps extends Exclude<React.HTMLAttributes<HTMLDivElement>, 'dangerouslySetInnerHTML'> {
    /**
     * The raw HTML string to be rendered
     * finally added via dangerouslySetInnerHTML
     */
    html: string
}

export default RenderHtml

const REGEX_A_TAG = /<a\s+(?:[^>]*?)?>/i // not global => finds the first occurrence only

// ReactParser Options @see https://github.com/remarkablemark/html-react-parser#replace-with-typescript
const parserOptions: HTMLReactParserOptions = {
    replace: (domNode) => {
        if ((domNode as DElment)?.attribs) {
            const { name, attribs, children } = domNode as DElment
            if (name === 'a' && attribs?.href) {
                const { href, ...props } = attributesToProps(attribs)
                return (
                    <AnyLink to={href} hasIcon={true} {...props}>
                        {domToReact(children as any, parserOptions)}
                    </AnyLink>
                )
            }
        }
    },
}
