import { TranslationTableBuildQuery } from '../../graphql-types-final'
import { graphqlFn } from '../types/Graphql'
import { LangKey } from '../types/Languages'

class TranslationHelper {
    graphql: graphqlFn
    table: TTranslationTable

    constructor(graphql: graphqlFn) {
        this.graphql = graphql
        this.table = { en: {}, de: {}, fr: {} }
    }

    translate(category: string, key: string, language: LangKey): string | undefined
    translate(category: string, key: string, language: null): ITranslationCategory | undefined
    translate(category: string, key: string, language: LangKey | null): ITranslationCategory | string | undefined {
        return tr(category, key, language as LangKey, this.table)
    }

    async buildTranslationTable(): Promise<void> {
        const table: TTranslationTable = { en: {}, de: {}, fr: {} }

        const graphql = this.graphql
        const result = await graphql<TranslationTableBuildQuery>(`
            query TranslationTableBuild {
                allDirectusTranslation {
                    edges {
                        node {
                            key
                            translation {
                                value
                                language
                            }
                            category
                        }
                    }
                }
            }
        `)

        const edges = result.data?.allDirectusTranslation.edges

        edges?.forEach((edge) => {
            const { node } = edge

            const categoryIndexKey = node.category as string
            const translationKey = node.key as string

            node.translation?.forEach((tl) => {
                const langKey = tl?.language as LangKey

                if (typeof table[langKey][categoryIndexKey] === 'object' && table[langKey][categoryIndexKey] !== null) {
                    table[langKey][categoryIndexKey][translationKey] = tl?.value || ''
                } else {
                    const newTranslationCategories: TTranslationKey = {}

                    table[langKey][categoryIndexKey] = newTranslationCategories
                    table[langKey][categoryIndexKey][translationKey] = tl?.value || ''
                }
            })
        })
        this.table = table
    }

    getLanguageTable(langKey: LangKey): ITranslationCategory {
        const langTable = this.table[langKey]
        return langTable
    }
}

export default TranslationHelper

// todo naming
const tr = ((
    category: string,
    key: string,
    language: LangKey | null,
    table: TTranslationTable,
): ITranslationCategory | string | undefined => {
    const fallbackLanguage = 'en'

    if (!language) {
        return table[fallbackLanguage][category][key]
    }

    if (table[language][category]) {
        if (table[language][category][key]) {
            if (table[language][category][key]) {
                return table[language][category][key]
            } else if (table[fallbackLanguage][category][key]) {
                console.debug(`Debug: missing translation for ${key} in ${language}. Using fallback language ${fallbackLanguage}`)
                return table[fallbackLanguage][category][key]
            } else {
                console.warn(`Warning: translation for key ${key} does not exist in fallback language`)
            }
        } else {
            console.error(`Error: translation for key ${key} does not exist`)
        }
    } else {
        console.error(`Error: translation category ${category} does not exist`)
    }

    return undefined
}) as {
    // type assertions for the t function
    (category: string, key: string, language: LangKey, table: TTranslationTable): string | undefined
    (category: string, key: string, language: null, table: TTranslationTable): TTranslationTable | undefined
}

const t = (category: string, key: string, table: ITranslationCategory): string | undefined => {
    if (table[category]) {
        if (table[category][key]) {
            return table[category][key]
        } else {
            console.error(`Error: translation for key ${key} does not exist`)
        }
    } else {
        console.error(`Error: translation category ${category} does not exist`)
    }

    return undefined
}
export const translate = t

export type TTranslationKey = Record<string, string>

export type TTranslationTable = Record<LangKey, ITranslationCategory>
export interface ITranslationCategory {
    [key: string]: TTranslationKey
}
