import axios from 'axios'
import React from 'react'
import { Alert, Col, Form, Row } from 'react-bootstrap'
import { isLoggedIn } from '../../../services/auth.js'
import Translation from '../../../types/Translation'
import ColoredBox from '../../content/ColoredBox'
import withTranslations from '../../hoc/withTranslations'
import ContentButton from '../../content/ContentButton'
import GenericForm from './GenericForm'
import * as styles from './LoginForm.module.scss'

class LoginForm extends React.Component<LoginFormProps, LoginFormState> {
    constructor(props: LoginFormProps) {
        super(props)

        if (props.lastLoginFormState) {
            this.state = props.lastLoginFormState
        } else {
            this.state = {
                form: {
                    email: '',
                    password: '',
                },
                authFailed: false,
                errorMsg: '',
                successMsg: '',
                forgotPassword: false,
                passwordReset: null,
            }
        }
    }

    componentWillUnmount() {
        if (this.props.onFormClose) {
            this.props.onFormClose(this.state)
        }
    }

    handleSubmit = async () => {
        if (this.props.acceptedTerms || !this.props.showError) {
            if (isLoggedIn()) {
                return
            }
            const result = await this.authenticate()

            if (this.props.onSubmit) {
                this.props.onSubmit(result)
            }
        } else {
            this.props.showError()
        }
    }

    authenticate = async () => {
        const formData = this.state.form
        const url = '/api/v1.0/Users/Authenticate'

        try {
            const response = await axios.post(url, formData)

            if (response.data && response.data.token) {
                return response.data
            } else {
                this.displayFormError(this.props.t('form', 'general.authentification.failed'))
            }
        } catch (error: any) {
            console.log(error)

            if (error.response) {
                console.log(`status code: ${error.response.status}`)
                console.log(error.response.data)
            }

            if (error.response.status === 403) {
                this.displayFormError(this.props.t('api', 'general.user.deactivated'))
            } else if (error.response.status === 429) {
                this.displayFormError(this.props.t('api', 'general.error.429'))
            } else if (error.response.status === 400) {
                this.displayFormError(this.props.t('api', 'general.authentification.failed'))
            } else {
                this.displayFormError(this.props.t('api', 'general.error.unexpected'))
            }
        }
    }

    handleChange = (e: any) => {
        const field = e.target.name
        const newState = {
            form: {
                ...this.state.form,
                [field]: e.target.value,
            },
        }
        this.setState(newState)
    }

    handleForgotPassword = () => {
        this.setState({
            forgotPassword: true,
        })
    }

    displayLoginContent = () => {
        this.setState({
            forgotPassword: false,
        })
    }

    handlePasswordReset = async () => {
        const emailData = {
            email: this.state.form.email,
        }
        const url = '/api/v1.0/Users/ResetPassword'

        try {
            await axios.post(url, emailData)

            this.setState({
                passwordReset: true,
                successMsg: this.props.t('form', 'resetpassword.success'),
            })
        } catch (error: any) {
            console.log(error)

            if (error.response) {
                console.log(`status code: ${error.response.status}`)
                console.log(error.response.data)
            }

            this.displayResetPasswordError(this.props.t('form', 'resetpassword.error.unexpected'))
        }
    }

    displayResetPasswordError(errorMsg: string) {
        this.setState({
            passwordReset: false,
            errorMsg,
        })
    }

    displayFormError(errorMsg: string) {
        this.setState({
            authFailed: true,
            errorMsg,
        })
    }

    render() {
        const { t } = this.props
        const { authFailed, errorMsg, successMsg, passwordReset, forgotPassword } = this.state

        const { email, password } = this.state.form

        let loginBtnText = t('template', 'general.link.text.login')
        let containerClass = ''
        let loginBtnRow = ''

        let formTitle = <h1>{t('template', 'general.link.text.login')}</h1>

        const forgotPasswordLink: any = (
            <Row>
                <Col sm={12}>
                    <Form.Group>
                        <button type={'button'} className={'btn btn-link'} onClick={this.handleForgotPassword}>
                            {t('form', 'login.password.forgot')}
                        </button>
                    </Form.Group>
                </Col>
            </Row>
        )

        if (this.props.download) {
            loginBtnText = t('form', 'eval.login.download.btn')
            containerClass = styles.downloadLoginForm
            loginBtnRow = styles.justifyBottom
            formTitle = <h2>{t('template', 'general.link.text.login')}</h2>
        }

        let formSubmit: (e: any) => void = this.handleSubmit

        if (forgotPassword) {
            formSubmit = this.handlePasswordReset
        }

        let formContent = (
            <Col sm={12} className={` ${styles.formContainer} ${containerClass}`}>
                <Row>
                    <Col sm={12}>{formTitle}</Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Form.Group controlId={'form.email'}>
                            <Form.Label>
                                {t('form', 'general.field.email.label')}
                                <span>*</span>
                            </Form.Label>
                            <Form.Control
                                type={'email'}
                                name={'email'}
                                required={true}
                                onChange={this.handleChange}
                                value={email}
                                autoFocus={true}
                                autoComplete="username"
                            />
                            <Form.Control.Feedback type="invalid">
                                {t('form', 'general.field.email.validate')}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Form.Group controlId={'form.password'}>
                            <Form.Label>
                                {t('form', 'general.field.password.label')}
                                <span>*</span>
                            </Form.Label>
                            <Form.Control
                                type={'password'}
                                name={'password'}
                                value={password}
                                required={true}
                                onChange={this.handleChange}
                                autoComplete="current-password"
                            />
                            <Form.Control.Feedback type="invalid">
                                {t('form', 'general.field.password.validate')}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                {forgotPasswordLink}

                <Row className={loginBtnRow}>
                    <Col sm={12}>
                        <ContentButton type="submit" disabled={this.props.loginDisabled} block={true}>
                            {loginBtnText}
                        </ContentButton>
                        {authFailed && <Alert variant={'danger'}>{errorMsg}</Alert>}
                    </Col>
                </Row>
            </Col>
        )

        if (forgotPassword) {
            formContent = (
                <Col sm={12}>
                    <Row>
                        <Col sm={12}>
                            <h1>{t('form', 'general.field.resetpassword.label')}</h1>
                        </Col>
                    </Row>
                    {successMsg && (
                        <Row>
                            <Col sm={12}>
                                <Alert variant={'success'}>{successMsg}</Alert>
                            </Col>
                        </Row>
                    )}

                    <Row>
                        <Col sm={12}>
                            <Form.Group controlId={'form.email'}>
                                <Form.Label>
                                    {t('form', 'general.field.email.label')}
                                    <span>*</span>
                                </Form.Label>
                                <Form.Control
                                    type={'email'}
                                    name={'email'}
                                    required={true}
                                    onChange={this.handleChange}
                                    value={email}
                                    autoFocus={true}
                                    autoComplete="username"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {t('form', 'general.field.email.validate')}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col sm={12}>
                            <Form.Group>
                                <ContentButton type="submit" block={true}>
                                    {t('form', 'resetpassword.reset')}
                                </ContentButton>
                            </Form.Group>

                            <Form.Group>
                                <button
                                    type={'button'}
                                    className={'btn btn-link'}
                                    disabled={this.props.loginDisabled}
                                    onClick={this.displayLoginContent}
                                >
                                    {t('form', 'resetpassword.backtologin')}
                                </button>
                            </Form.Group>
                            {passwordReset === false && <Alert variant={'danger'}>{errorMsg}</Alert>}
                        </Col>
                    </Row>
                </Col>
            )
        }

        return (
            <GenericForm onSubmit={formSubmit}>
                <ColoredBox noWidth={true} spaceBottom={false}>
                    <div className={styles.coloredBox}>{formContent}</div>
                </ColoredBox>
            </GenericForm>
        )
    }
}

export default withTranslations(LoginForm)

interface LoginFormProps extends Translation {
    onSubmit?: any
    onFormClose?: any
    lastLoginFormState?: any
    download?: boolean
    loginDisabled?: boolean
    acceptedTerms?: boolean
    showError?: any
}

interface LoginFormState {
    form: {
        email: string
        password: string
    }
    authFailed: boolean
    errorMsg: string
    successMsg: string
    passwordReset: boolean | null
    forgotPassword: boolean
}
