import { Link } from 'gatsby'
import * as React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { LogoPDF } from '../../images/Logos'
import PageContext from '../../types/PageContext'
import MobileNavigationControls from '../layout/mobile_navigation/MobileNavigationControls'
import HeadNavigation from '../layout/navigation/HeadNavigation'
import MainNavigation from '../layout/navigation/MainNavigation'
import UserSection from '../layout/UserSection'
import * as styles from './Header.module.scss'

export default class Header extends React.Component<HeaderProps> {
    public render(): React.ReactNode {
        const { pageContext } = this.props

        const logoLink = `/${pageContext.language}/`

        return (
            <header className={styles.header}>
                <Container className="my-0">
                    <Row>
                        <Col md={4}>
                            <section className={styles.headerLogo}>
                                <Link to={logoLink}>
                                    <Image src={LogoPDF} fluid={true} alt="Logo PDF Tools" />
                                </Link>
                            </section>
                            <MobileNavigationControls
                                mobileCollapsed={this.props.mobileCollapsed}
                                onMenuControlClick={this.props.toggleMenuVisibility}
                                pageContext={pageContext}
                            />
                        </Col>
                        <Col md={8} className="d-none d-md-block text-right">
                            <HeadNavigation pageContext={pageContext} />
                            <UserSection pageContext={pageContext} />
                        </Col>
                    </Row>
                    <Row className="d-none d-md-block">
                        <Col>
                            <MainNavigation pageContext={pageContext} />
                        </Col>
                    </Row>
                </Container>
            </header>
        )
    }
}

interface HeaderProps {
    pageContext: PageContext<any>
    mobileCollapsed: boolean
    toggleMenuVisibility: boolean
}
