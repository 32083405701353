import React, { ReactNode } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { IBreadcrumb } from '../../../types/Breadcrumbs'
import PageContext from '../../../types/PageContext'
import AnyLink from '../../AnyLink'
import * as styles from './Breadcrumbs.module.scss'

const Breadcrumbs: React.FunctionComponent<BreadcrumbsProps> = (props: BreadcrumbsProps) => {
    const breadcrumbItems: ReactNode[] = []
    const breadcrumbs = props.pageContext.breadcrumbs

    if (breadcrumbs) {
        breadcrumbs.forEach((b: IBreadcrumb) => {
            if (b.active) {
                breadcrumbItems.push(<span>{b.label}</span>)
            } else if (!b.url) {
                breadcrumbItems.push(<span className={styles.breadcrumbArrow}>{b.label} </span>)
            } else {
                breadcrumbItems.push(
                    <span className={styles.breadcrumbArrow}>
                        <AnyLink to={b.url}>{b.label}</AnyLink>
                    </span>,
                )
            }
        })
    }

    return (
        <Container className="mb-0">
            <Row>
                <Col>{breadcrumbItems}</Col>
            </Row>
        </Container>
    )
}

interface BreadcrumbsProps {
    pageContext: PageContext<any>
}

export default Breadcrumbs
