import * as React from 'react'
import * as styles from './AnchorLink.module.scss'

const AnchorLink: React.FunctionComponent<AnchorLinkProps> = (props: AnchorLinkProps) => {
    const { anchor, title, anchorKey } = props

    let anchorLink = (
        <div className={styles.anchorWrapper}>
            <a href={anchor} className={styles.anchorLink} title={title}>
                {anchorKey}
            </a>
            <span className={styles.pipe}>|</span>
        </div>
    )

    if (!anchor) {
        anchorLink = (
            <div className={styles.anchorWrapper}>
                <span className={styles.emptyEntry}>{anchorKey}</span>
                <span className={styles.pipe}>|</span>
            </div>
        )
    }

    return anchorLink
}

interface AnchorLinkProps {
    anchor?: string
    title?: string
    anchorKey: string
}

export default AnchorLink
