import * as React from 'react'
import { Image, ImageProps } from 'react-bootstrap'
import { IImage } from '../../types/Image'

const ContentImage: React.FunctionComponent<ContentImageProps> = (props: ContentImageProps) => {
    const { image, ...imageProps } = props

    return <Image src={image.src} alt={image.alt} title={image.title} {...imageProps} />
}

export interface ContentImageProps extends ImageProps {
    image: IImage
}

export default ContentImage
