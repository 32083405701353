import _cloneDeep from 'clone-deep'
import React from 'react'
import postifyData, { findCountryOption } from '../../../helper/FormHelper'
import { saveEvalForm } from '../../../helper/SessionStorage'
import { handleLogin } from '../../../services/auth.js'
import { IFilterOption } from '../../../types/Common'
import { ICountries } from '../../../types/Country'
import GlobalState from '../../../types/GlobalState'
import Location from '../../../types/Location'
import PageContext from '../../../types/PageContext'
import Translation from '../../../types/Translation'
import { IUser } from '../../../types/User'
import axios from '../../../utils/axios'
import withLocation from '../../hoc/withLocation'
import withState from '../../hoc/withState'
import withTranslations from '../../hoc/withTranslations'
import RegistrationForm from './RegistrationForm'

class Registration extends React.Component<RegistrationProps, RegistrationState> {
    state = {
        errorMsg: '',
        successMsg: '',
    }

    handleSubmit = async (formState: any) => {
        this.saveFormToSession('reg')
        const result = await this.registerUser(formState)

        const accepedTerms = this.props.accepedTerms
        if (result && result.token && accepedTerms) {
            this.loginAndRedirect(result)
        }
    }

    saveFormToSession = (type: string) => {
        const { templateData } = this.props.pageContext
        const { product } = templateData

        const productCode = '_' + product.code
        const platformCode = this.props.platform.value
        const productName = product.name
        saveEvalForm(productCode, platformCode, productName, type)
    }

    registerUser = async (formState: any) => {
        const { templateData } = this.props.pageContext
        const { product } = templateData
        const productCode = product.code
        const productVriant = this.props.productVariant
        const platformCode = this.props.platform.label

        const url = `/api/v1.0/Users/RegisterTrial/?product=${productCode}&platform=${productVriant}&os=${platformCode}`

        const customerState = _cloneDeep(formState.form.customer)
        const copyFormState = _cloneDeep(formState.form)

        const customerData = postifyData(customerState)
        const formData = postifyData(copyFormState)

        formData.customer = customerData

        try {
            const result = await axios.post(url, formData, {
                headers: {
                    'Accept-Language': formData.language || this.props.pageContext.language,
                },
            })
            return result.data as IUser
        } catch (error) {
            console.log(error)

            if (error.response) {
                console.log(`status code: ${error.response.status}`)
                console.log(error.response.data)
            }

            if (error.response.status === 400) {
                this.displayFormError(this.props.t('api', 'registration.email.error.exists'))
            } else if (error.response.status === 429) {
                this.displayFormError(this.props.t('api', 'general.error.429'))
            } else {
                this.displayFormError(this.props.t('api', 'general.error.unexpected'))
            }
        }

        return undefined
    }

    loginAndRedirect = (userData: any) => {
        const { token, fullName } = userData
        const loginSuccess = handleLogin(token, fullName)

        if (loginSuccess) {
            const action = {
                type: 'login',
                payload: {
                    username: fullName,
                },
            }

            this.props.dispatch(action)
            this.redirect()
        }
    }

    redirect = () => {
        const { mypdfUrls } = this.props.pageContext
        let forwardTo = mypdfUrls?.mypdfHome || ''

        const { search } = this.props

        if (this.props.accepedTerms && this.props.evalDownloadUrl) {
            forwardTo = this.props.evalDownloadUrl
        } else if (search && search.redirect) {
            forwardTo = search.redirect
        }

        this.props.navigate(forwardTo)
    }

    displayFormError = (errorMsg: string) => {
        this.setState({
            successMsg: '',
            errorMsg,
        })
    }

    render() {
        const { errorMsg, successMsg } = this.state
        const { t } = this.props

        const { pageContext, children } = this.props

        const { language } = pageContext

        let defaultCountryOpt = this.props.countryOptions[0]
        let defaultLangOpt = this.props.languageOptions[0]

        const countryOptSwitzerland = findCountryOption('Switzerland', this.props.countryOptions)

        if (countryOptSwitzerland) {
            defaultCountryOpt = countryOptSwitzerland
        }

        const langOptReal = this.props.languageOptions.find((langOpt) => langOpt.value === language)

        if (langOptReal) {
            defaultLangOpt = langOptReal
        }

        const emailAddrInfo = t('form', 'account.profile.email.info') + ' ' + t('form', 'registration.email.info.text')
        const dataProtection = (
            <p>
                {t('form', 'registration.dataprotection.text')}{' '}
                <a href="/">{t('form', 'registration.dataprotection.link.text')}</a>
            </p>
        )

        return (
            <RegistrationForm
                countryOptions={this.props.countryOptions}
                languageOptions={this.props.languageOptions}
                onSubmit={this.handleSubmit}
                errorMsg={errorMsg}
                successMsg={successMsg}
                emailAddrInfo={emailAddrInfo}
                submitBtnText={t('form', 'registration.btn.createprofile')}
                disableSpinner={true}
                additionalInfo={dataProtection}
                // defaultCountryOpt={defaultCountryOpt}
                defaultLangOpt={defaultLangOpt}
            >
                {children && children}
            </RegistrationForm>
        )
    }
}

export default withLocation(withState(withTranslations(Registration)))

interface RegistrationProps extends Translation, GlobalState, Location<any> {
    countryOptions: IFilterOption[]
    countries: ICountries
    languageOptions: IFilterOption[]
    pageContext: PageContext<any>
    evalDownloadUrl?: string
    accepedTerms?: boolean
    platform: IFilterOption
    productVariant: string
}

interface RegistrationState {
    errorMsg: string
    successMsg: string
}
