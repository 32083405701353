import * as React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import * as styles from './Banner.module.scss'

const Banner: React.FunctionComponent<BannerProps> = (props: BannerProps) => {
    const { headerText, pdftColor, imageSrc, textColor, bgColor, header, subHeader, shortenText, children } = props
    let backgroundColor = ''
    let bannerStyle = {}
    let textColorStyle = {}
    let bgColorStyle = {}
    let subHeaderContent: any
    const shortHeaderClass = shortenText ? styles.short : ''

    if (imageSrc) {
        bannerStyle = getBannerStyle(imageSrc)
    }

    if (textColor) {
        textColorStyle = getTextColorStyle(textColor)
    }

    if (bgColor) {
        bgColorStyle = getBgColorStyle(bgColor)
    }

    if (pdftColor) {
        backgroundColor = styles[pdftColor]
    }

    if (subHeader) {
        subHeaderContent = <span className={styles.header}>{subHeader}</span>
    }

    const classes = `${styles.width} ${backgroundColor} ${styles.banner} full-width`

    return (
        <div className={classes} style={imageSrc ? bannerStyle : bgColorStyle}>
            <Container className="my-0">
                <Row>
                    <Col sm={12}>
                        <section className={styles.bannerContent} style={textColorStyle}>
                            <header className={`${styles.header} ${shortHeaderClass}`}>
                                <h2>{header}</h2>
                                {subHeaderContent}
                            </header>
                            {headerText && <p className={shortHeaderClass}>{headerText}</p>}
                        </section>
                    </Col>
                </Row>

                {children && (
                    <Row>
                        <Col className="pt-2">{children}</Col>
                    </Row>
                )}
            </Container>
        </div>
    )
}

const getBannerStyle = (imageSrc: string) => {
    const style = {
        backgroundImage: `url(${imageSrc})`,
        backgroundRepeat: 'no-repeat',
    }

    return style
}

const getTextColorStyle = (textColor: string) => {
    const style = {
        color: textColor,
    }
    return style
}

const getBgColorStyle = (bgColor: string) => {
    const style = {
        background: bgColor,
    }
    return style
}

interface BannerProps {
    /**
     * Can be overwritten by bgColor.
     */
    pdftColor?: string
    textColor?: string
    bgColor?: string
    imageSrc?: any
    small?: boolean
    subHeader?: string
    header: string
    headerText?: string
    shortenText?: boolean
    children?: React.ReactNode
}

Banner.defaultProps = {
    pdftColor: 'blue',
    textColor: 'white',
    shortenText: false,
}

export default Banner
