import { LangKey } from '../types/Languages'

/**
 * short:       d.m.yyyy => '4.3.2021'
 * paddedShort: dd.mm.yyyy => '04.03.2021'
 * long:         d. mmm yyyy => '4. März 2021'
 */
export type DateFormat = 'short' | 'paddedShort' | 'long'

export const formatDate = (date: Date | string, lang: LangKey, format: DateFormat = 'paddedShort'): string => {
    const config = { day: '2-digit', month: '2-digit', year: 'numeric' }

    switch (format) {
        case 'long':
            config.day = 'numeric'
            config.month = 'long'
            break
        // case 'paddedShort':
        //     config.day = '2-digit'
        //     config.month = '2-digit'
        //     break
        case 'short':
            config.day = 'numeric'
            config.month = 'numeric'
            break
    }

    if (typeof date === 'string') {
        date = new Date(date)
    }

    return date?.toLocaleDateString(lang, config)
}
