import * as React from 'react'
import { Col, Row } from 'react-bootstrap'
import { INavMenu } from '../../../types/Navigation'
import PageContext from '../../../types/PageContext'
import Translation from '../../../types/Translation'
import ContentButton from '../../content/ContentButton'
import withTranslations from '../../hoc/withTranslations'
import MenuItem from './MenuItem'
import * as styles from './MenuTab.module.scss'
import MenuTabTitle from './MenuTabTitle'

const MenuTab: React.FunctionComponent<MenuTabProps> = (props: MenuTabProps) => {
    const {
        columnTitleLeft,
        columnTitleRight,
        menuItemsLeft,
        menuItemsRight,
        footerMenuItems,
        footerMenuItemsRight,
    } = props.navMenu
    const hasCareer = props.hasCareer

    const { pageContext, t } = props
    const { headLinks } = pageContext

    let contactUrl = ''
    if (headLinks) {
        contactUrl = headLinks.contact?.[pageContext.language] || ''
    }

    const menuItemsLeftContainer: React.ReactElement[] = []
    const menuItemsRightContainer: React.ReactElement[] = []
    const footerItemsLeft: React.ReactElement[] = []
    const footerItemsRight: React.ReactElement[] = []

    menuItemsLeft.forEach((menuItem, index: number) => {
        menuItemsLeftContainer.push(
            <MenuItem
                title={menuItem.title}
                description={menuItem.description}
                to={hasCareer && index === 5 ? 'https://smallpdf.com/careers' : menuItem.to}
                key={index}
                pdftColor={menuItem.pdftColor}
            />,
        )
    })

    menuItemsRight.forEach((menuItem, index: number) => {
        menuItemsRightContainer.push(
            <MenuItem
                title={menuItem.title}
                description={menuItem.description}
                to={menuItem.to}
                key={index}
                pdftColor={menuItem.pdftColor}
                block={true}
            />,
        )
    })

    footerMenuItems.forEach((menuItem, index: number) => {
        footerItemsLeft.push(<MenuItem title={menuItem.title} to={menuItem.to} key={index} pdftColor={menuItem.pdftColor} />)
    })

    footerMenuItemsRight.forEach((menuItem, index: number) => {
        footerItemsRight.push(
            <MenuItem title={menuItem.title} to={menuItem.to} key={index} block={true} pdftColor={menuItem.pdftColor} />,
        )
    })

    const footerRow = (
        <Row className={styles.tabFooter}>
            <Col sm={8}>
                <Row>{footerItemsLeft}</Row>
            </Col>
            <Col sm={4}>
                <Row>
                    {footerItemsRight}
                    <Col sm={12}>
                        <ContentButton block={true} to={contactUrl}>
                            {t('template', 'navigation.button.contactRequest.label')}
                        </ContentButton>
                    </Col>
                </Row>
            </Col>
        </Row>
    )

    const displayFooter = !!footerItemsLeft.length
    const twoColumn = !!menuItemsRightContainer.length

    let columns = (
        <Row>
            <Col sm={12}>
                <Row>
                    <MenuTabTitle title={columnTitleLeft.title} subTitle={columnTitleLeft.subTitle} />
                </Row>
                <Row>{menuItemsLeftContainer}</Row>
            </Col>
        </Row>
    )

    if (twoColumn) {
        columns = (
            <>
                <Row>
                    <Col sm={8}>
                        <Row>
                            <MenuTabTitle title={columnTitleLeft.title} subTitle={columnTitleLeft.subTitle} />
                        </Row>
                        <Row>{menuItemsLeftContainer}</Row>
                    </Col>
                    <Col sm={4}>
                        <Row>
                            <MenuTabTitle title={columnTitleRight.title} subTitle={columnTitleRight.subTitle} />
                        </Row>
                        <Row>{menuItemsRightContainer}</Row>
                    </Col>
                </Row>
                {displayFooter && footerRow}
            </>
        )
    }

    return <>{columns}</>
}

interface MenuTabProps extends Translation {
    navMenu: INavMenu
    pageContext: PageContext<any>
    hasCareer: boolean
}

export default withTranslations(MenuTab)
