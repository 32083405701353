import { navigate } from 'gatsby'
import * as React from 'react'
import { getUserFullname, isLoggedIn } from '../../services/auth.js'
import GlobalState from '../../types/GlobalState'
import Location from '../../types/Location'
import PageContext from '../../types/PageContext'
import Login from '../content/LoginByButton'
import Logout from '../content/Logout'
import withLocation from '../hoc/withLocation'
import withState from '../hoc/withState'

class UserSection extends React.Component<UserSectionProps, UserSectionState> {
    async componentDidMount() {
        const { loggedIn } = this.props.state

        if (isLoggedIn() && !loggedIn) {
            const userFullname = getUserFullname()

            if (userFullname) {
                const action = {
                    type: 'login',
                    payload: {
                        username: userFullname,
                    },
                }

                this.props.dispatch(action)
            }
        }
    }

    handleUserLogin = (username: string) => {
        const action = {
            type: 'login',
            payload: {
                username,
            },
        }

        this.props.dispatch(action)

        const { pageContext } = this.props
        const { mypdfUrls } = pageContext

        const mypdfHome = mypdfUrls?.mypdfHome || ''
        const mypdfEntry = mypdfUrls?.mypdfEntry || ''

        const { pathname, search } = this.props.location

        let forwardTo = mypdfHome

        if (pathname.includes(mypdfEntry) && pathname !== mypdfEntry) {
            forwardTo = pathname + search
        }

        navigate(forwardTo)
    }

    handleUserLogout = () => {
        this.props.dispatch({ type: 'logout' })

        const { pageContext, location } = this.props
        const { mypdfUrls } = pageContext
        const mypdfEntry = mypdfUrls?.mypdfEntry || ''

        const pageIsMyPdf = location.pathname.includes(mypdfEntry)

        if (pageIsMyPdf) {
            navigate(mypdfEntry, {
                state: { loggedOut: true },
            })
        }
    }

    render() {
        const { loggedIn, username } = this.props.state

        let nav = <Login onLogin={this.handleUserLogin} />

        if (loggedIn) {
            nav = (
                <div>
                    {username && `${username} |`} <Logout onLogout={this.handleUserLogout} />
                </div>
            )
        }

        return <div>{nav}</div>
    }
}

export default withState(withLocation(UserSection))

interface UserSectionProps extends GlobalState, Location<any> {
    pageContext: PageContext<any>
}

interface UserSectionState {}
