import { graphql, Link, useStaticQuery } from 'gatsby'
import * as React from 'react'
import PageContext from '../../../types/PageContext'
import Translation from '../../../types/Translation'
import AnyLink from '../../AnyLink'
import LanguageSelect from '../../content/LanguageSelect'
import withTranslations from '../../hoc/withTranslations'
import * as styles from './HeadNavigation.module.scss'

const HeadNavigation: React.FunctionComponent<HeadNavigationProps> = (props: HeadNavigationProps) => {
    const { t, pageContext } = props

    return (
        <div className={'text-right d-none d-md-block'}>
            <LanguageSelect pageContext={pageContext} />
        </div>
    )
}

interface HeadNavigationProps extends Translation {
    pageContext: PageContext<any>
}

export default withTranslations(HeadNavigation)
