import * as React from 'react'
import { Col, Row } from 'react-bootstrap'
import * as styles from './AnchorNavigation.module.scss'

const AnchorNavigation: React.FunctionComponent<AnchorLinkProps> = (props: AnchorLinkProps) => {
    const { children } = props

    return (
        <Row>
            <Col sm={12}>
                <nav className={styles.anchorNav}>{children}</nav>
            </Col>
        </Row>
    )
}

interface AnchorLinkProps {
    children: React.ReactNode
}

export default AnchorNavigation
