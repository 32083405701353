import * as React from 'react'
import PageContext from '../../../types/PageContext'

import { IFilterOption } from '../../../types/Common'
import { ICountries } from '../../../types/Country'
import { IAccountFormPluginData } from '../../../types/Plugin'
import Registration from '../../mypdftools/registration/Registration'

class MyPDFRegisterPlugin extends React.Component<Props> {
    render() {
        const { pageContext, pluginData, evalDownloadUrl, acceptedTerms, children, platform, productVariant } = this.props

        const { language } = pageContext

        let countries: ICountries = { en: [], de: [], fr: [] }
        let languageOpts: IFilterOption[] = []
        let countryOpts: IFilterOption[] = []

        if (pluginData) {
            countries = pluginData.countries
            countryOpts = pluginData.countries[language]
            languageOpts = pluginData.languageOptions[language]
        } else if (this.props.countries && this.props.langOptions) {
            countryOpts = this.props.countries
            languageOpts = this.props.langOptions
        }

        return (
            <Registration
                countryOptions={countryOpts}
                countries={countries}
                languageOptions={languageOpts}
                pageContext={pageContext}
                evalDownloadUrl={evalDownloadUrl}
                accepedTerms={acceptedTerms}
                platform={platform}
                productVariant={productVariant}
            >
                {children && children}
            </Registration>
        )
    }
}

export default MyPDFRegisterPlugin

interface Props {
    config: any
    pageContext: PageContext<any>
    pluginData: IAccountFormPluginData
    evalDownloadUrl?: string
    countries?: IFilterOption[]
    langOptions?: IFilterOption[]
    acceptedTerms?: boolean
    platform: IFilterOption
    productVariant: string
}
