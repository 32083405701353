import { Link } from 'gatsby'
import * as React from 'react'
import { allLanguageKeys } from '../../types/Languages'
import Location from '../../types/Location'
import { IMyPDFTemplateData } from '../../types/MyPDF'
import PageContext from '../../types/PageContext'
import withLocation from '../hoc/withLocation'
import * as styles from './LanguageSelect.module.scss'

const LanguageSelect: React.FunctionComponent<LanguageSelectProps> = (props: LanguageSelectProps) => {
    const { pageContext, location } = props
    const { langLinks, mypdfUrls } = pageContext
    const currentLanguage = pageContext.language

    let languageOptions = langLinks

    if (mypdfUrls) {
        const { mypdfEntry, pageLookup, mypdfLangLinks } = mypdfUrls

        if (location.pathname.includes(mypdfEntry)) {
            const path = location.pathname
            if (path !== mypdfEntry && pageLookup[path]) {
                const pageId = pageLookup[path]

                if (mypdfLangLinks[pageId]) {
                    languageOptions = mypdfLangLinks[pageId]
                }
            }
        }
    }

    // This hack is needed for the change-password page because it is not in directus
    // it's only defined as a reach router route in src\templates\MyPDFToolsPageTemplate.tsx
    const getResetPasswordLinkForLanguage = (lang: string) => {
        const path = location.pathname;

        if (path.includes('change-password')) {
            return changeLanguage(path, lang)
        }
        return null
    }

    return (
        <ul className={styles.langSelect}>
            {allLanguageKeys
                .filter((lang) => languageOptions?.[lang]) // display available languages only
                .map((lang) => (
                    <li key={lang}>
                        <Link to={getResetPasswordLinkForLanguage(lang) || languageOptions[lang]} className={lang === currentLanguage ? 'active-link' : ''}>
                            {lang.toUpperCase()}
                        </Link>
                    </li>
                ))}
        </ul>
    )
}

interface LanguageSelectProps extends Location<any> {
    pageContext: PageContext<IMyPDFTemplateData | any>
}

export default withLocation(LanguageSelect)

function changeLanguage(url: string, newLanguage: string): string {
    const parts = url.split('/');

    if (parts.length > 2) {
        parts[1] = newLanguage;
    }

    return parts.join('/');
}