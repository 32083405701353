import * as React from 'react'
import { Col } from 'react-bootstrap'
import AnyLink from '../../AnyLink'
import * as styles from './MenuItem.module.scss'

const MenuItem: React.FunctionComponent<MenuItemProps> = (props) => {
    const { to, title, description, pdftColor, block } = props
    let classes = styles.menuItem
    let colWidth = 6

    if (pdftColor) {
        classes += ' ' + styles[pdftColor]
    }

    if (block) {
        colWidth = 12
    }

    return (
        <Col sm={colWidth}>
            <AnyLink to={to} className={classes} activeClassName={'active-menu-item'}>
                <div className={`${styles.title} ignore-click`}>{title}</div>
                {description && <div className={`${styles.description} ignore-click`}>{description}</div>}
            </AnyLink>
        </Col>
    )
}

interface MenuItemProps {
    to: string
    title: string
    description?: string
    pdftColor?: string
    block?: boolean
}

MenuItem.defaultProps = {
    block: false,
    to: '/',
}

export default MenuItem
