import React from 'react'

import { GlobalDispatchContext, GlobalStateContext } from '../../context/GlobalContextProvider'

const withState = <P extends object>(ComponentToWrap: React.ComponentType<P>) => {
    return (props: any) => {
        return (
            <GlobalStateContext.Consumer>
                {(state) => (
                    <GlobalDispatchContext.Consumer>
                        {(dispatchCtx) => <ComponentToWrap state={state} dispatch={dispatchCtx.dispatch} {...props} />}
                    </GlobalDispatchContext.Consumer>
                )}
            </GlobalStateContext.Consumer>
        )
    }
}

export default withState
