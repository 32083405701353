import { Link, navigate } from 'gatsby'
import * as React from 'react'
import { isLoggedIn } from '../../../services/auth.js'
import GlobalState from '../../../types/GlobalState.js'
import Location from '../../../types/Location.js'
import { IMenuItem, INavMenu, NavMenusIndexKey } from '../../../types/Navigation.js'
import PageContext from '../../../types/PageContext'
import Translation from '../../../types/Translation'
import AnyLink from '../../AnyLink'
import LanguageSelect from '../../content/LanguageSelect'
import Logout from '../../content/Logout'
import withLocation from '../../hoc/withLocation'
import withState from '../../hoc/withState'
import withTranslations from '../../hoc/withTranslations'
import MobileDropdownMenu from './MobileDropdownMenu'
import * as styles from './MobileNavigation.module.scss'

class MobileNavigation extends React.Component<MobileNavigationProps, MobileNavigationState> {
    state: MobileNavigationState = {
        activeMenuRef: '',
    }

    toggleActiveMenu = (ref: React.RefObject<any>) => {
        const activeMenuRef = this.state.activeMenuRef

        if (activeMenuRef) {
            activeMenuRef.classList.toggle('dropdown-show-mobile')
        }

        if (ref.current !== activeMenuRef) {
            ref.current.classList.toggle('dropdown-show-mobile')
            this.setState({ activeMenuRef: ref.current })
        } else {
            this.setState({ activeMenuRef: '' })
        }
    }

    handleMenuItemClick = () => {
        if (this.props.toggleMenuVisibility) {
            this.props.toggleMenuVisibility()
        }
    }

    handleUserLogout = () => {
        this.props.dispatch({ type: 'logout' })

        const { pageContext, location } = this.props
        const { mypdfUrls } = pageContext

        if (!mypdfUrls) {
            return
        }

        const { mypdfEntry } = mypdfUrls

        const pageIsMyPdf = location.pathname.includes(mypdfEntry)

        if (pageIsMyPdf) {
            navigate(mypdfEntry, {
                state: { loggedOut: true },
            })
        }

        if (this.props.toggleMenuVisibility) {
            this.props.toggleMenuVisibility()
        }
    }

    public render() {
        const { pageContext, t, mobileCollapsed } = this.props
        const { navigation } = pageContext

        const { mypdfUrls, headLinks } = pageContext
        let loginUrl = ''

        if (mypdfUrls) {
            loginUrl = mypdfUrls.mypdfEntry
        }

        let contactUrl = ''
        if (headLinks) {
            contactUrl = headLinks.contact[pageContext.language] || ''
        }

        const menus: any[] = []

        if (navigation) {
            Object.keys(navigation).forEach((k) => {
                const menuIndexKey = k as NavMenusIndexKey
                const menu = navigation[menuIndexKey]
                const renderedMenu = this.renderMenu(menu, menuIndexKey)

                if (renderedMenu) {
                    menus.push(renderedMenu)
                }
            })
        }

        let logInOut = (
            <li>
                <Link to={loginUrl}>{t('template', 'general.link.text.login')}</Link>
            </li>
        )

        if (isLoggedIn()) {
            logInOut = (
                <li>
                    <Logout onLogout={this.handleUserLogout} mobile={true} />
                </li>
            )
        }

        return (
            <div id="navigation_mobile" className={`hidden-print ${styles.mobileNavigation}`}>
                {!mobileCollapsed && (
                    <div className={styles.mainWrapper}>
                        <ul id="navigation_mobile_main" className={`${styles.mobileMain}`}>
                            {menus}
                        </ul>
                        <ul className={`${styles.meta}`}>
                            {logInOut}
                            <li>
                                <AnyLink to="https://www.pdf-tools.com/" target="_self">
                                    &#x1F890; exit My PDF Tools
                                </AnyLink>
                            </li>
                        </ul>
                        <div className="mobile-language-select">
                            <LanguageSelect pageContext={pageContext} />
                        </div>
                    </div>
                )}
            </div>
        )
    }

    renderMenu = (menu: INavMenu, menuIndexKey: NavMenusIndexKey) => {
        const isProductMenu = menuIndexKey === 'products'
        const isFooter = menuIndexKey === ('footer' as string)
        const isMypdfTools = menuIndexKey === 'mypdftools'

        if (isFooter) {
            return null
        }

        if (isMypdfTools && !isLoggedIn()) {
            return null
        }

        if (isProductMenu) {
            return this.renderProductsMenu(menu, menuIndexKey)
        }

        const menuItems = menu.menuItemsLeft.map(this.renderMenuItem)

        return (
            <MobileDropdownMenu
                title={menu.title}
                onDropdownChange={this.toggleActiveMenu}
                menuEntryLink={menu.menuEntryLink}
                key={`dropkey-${menuIndexKey}`}
                urlPath={menu.menuEntryLink}
            >
                {menuItems}
            </MobileDropdownMenu>
        )
    }

    renderMenuItem = (menuItem: IMenuItem, i: number) => {
        let pdftColor
        menuItem.pdftColor ? (pdftColor = styles[menuItem.pdftColor]) : (pdftColor = styles.blue)

        return (
            <li key={`men-${i}`}>
                <Link to={menuItem.to} activeClassName={pdftColor} onClick={this.handleMenuItemClick}>
                    {menuItem.title}
                </Link>
            </li>
        )
    }

    renderProductsMenu = (menu: INavMenu, menuIndexKey: NavMenusIndexKey) => {
        const groupOne: any[] = []
        const groupTwo: any[] = []

        const groupMenuItemOne = (
            <li className={styles.groupedTitle} key={`grouped-${menu.columnTitleLeft.title}`}>
                <strong>{menu.columnTitleLeft.title}</strong>
            </li>
        )

        groupOne.push(groupMenuItemOne)
        const menuItemsGroupOne = menu.menuItemsLeft.map(this.renderMenuItem)
        groupOne.push(...menuItemsGroupOne)

        const groupMenuItemTwo = (
            <li className={styles.groupedTitle} key={`grouped-${menu.columnTitleRight.title}`}>
                <strong>{menu.columnTitleRight.title}</strong>
            </li>
        )

        groupTwo.push(groupMenuItemTwo)
        const menuItemsGroupTwo = menu.menuItemsRight.map(this.renderMenuItem)
        groupTwo.push(...menuItemsGroupTwo)

        return (
            <MobileDropdownMenu
                title={menu.title}
                onDropdownChange={this.toggleActiveMenu}
                menuEntryLink={menu.menuEntryLink}
                key={`dropkey-${menuIndexKey}`}
                urlPath={menu.menuEntryLink}
            >
                {groupOne}
                {groupTwo}
            </MobileDropdownMenu>
        )
    }
}

export default withState(withLocation(withTranslations(MobileNavigation)))

interface MobileNavigationProps extends GlobalState, Location<any>, Translation {
    pageContext: PageContext<any>
    toggleMenuVisibility: any
    mobileCollapsed: boolean
}

interface MobileNavigationState {
    activeMenuRef: any
}
