import { Link } from 'gatsby'
import * as React from 'react'
import * as styles from './MobileDropdownMenu.module.scss'

export default class MobileDropdownMenu extends React.Component<DropdownProps, DropdownState> {
    myRef = React.createRef<HTMLLIElement>()

    componentDidMount() {
        const locationPath = window.location.pathname
        const { urlPath } = this.props

        if (locationPath.startsWith(urlPath)) {
            this.props.onDropdownChange(this.myRef)
        }
    }

    handleDropdownToggleClick = (e: any) => {
        e.preventDefault()
        this.props.onDropdownChange(this.myRef)
    }

    render() {
        const { title, children, menuEntryLink } = this.props

        const classes = 'dropdown-mobile'

        return (
            <li className={classes} ref={this.myRef}>
                <Link
                    to={menuEntryLink}
                    onClick={this.handleDropdownToggleClick}
                    partiallyActive={true}
                    className={styles.menuEntryLink}
                >
                    {title}
                </Link>
                <ul className={'dropdown-menu-mobile'}>{children}</ul>
            </li>
        )
    }
}

interface DropdownProps {
    title: string
    onHover?: any
    onDropdownChange: any
    menuEntryLink: string
    urlPath: string
}

interface DropdownState {}
