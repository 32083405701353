import * as React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { IFooterLink, IFooterLinkGroup } from '../../types/Navigation'
import Translation from '../../types/Translation'
import AnyLink from '../AnyLink'
import ContentButton from '../content/ContentButton'
import PdfToolsAddress from '../content/PdfToolsAddress'
import withTranslations from '../hoc/withTranslations'
import * as styles from './Footer.module.scss'

class Footer extends React.Component<FooterProps> {
    public render() {
        const footerNavigation = this.props.footerNavigation
        const { t } = this.props

        // fix for 'static' pages in src/pages folder

        const footer: any = {
            newsletter_subscribe: '/unsubscribe',
        }

        return (
            <footer className={styles.footer}>
                <Container className="my-0">
                    <Row>
                        <Col xs={12} md={4}>
                            <PdfToolsAddress />
                        </Col>
                        <Col xs={6} md={4}>
                            <ul>
                                <li key="link-blog">
                                    <AnyLink to="https://smallpdf.com/careers" rel="noopener">
                                        Career
                                    </AnyLink>
                                </li>
                            </ul>
                        </Col>
                        <Col xs={6} md={4}>
                            <ul>
                                <li>
                                    <AnyLink to="https://www.pdf-tools.com/" target="_self">
                                        &#x1F890; exit My PDF Tools
                                    </AnyLink>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </footer>
        )
    }
}

export default withTranslations(Footer)

interface FooterProps extends Translation {
    footerNavigation: any
}
