import * as React from 'react'
import { ButtonProps } from 'react-bootstrap'
import AnyLink from '../AnyLink'

const AnyLinkButton: React.FunctionComponent<BtnProps> = (props: BtnProps) => {
    const { to, children, variant, pdftColor, upperCase, block, size, active, disabled, className, fullheight } = props

    let classes = `btn btn-${variant || 'primary'} mr-2`

    if (pdftColor) {
        classes += ' ' + pdftColor
    }
    if (upperCase) {
        classes += ' btn-upper'
    }
    if (block && !fullheight) {
        classes += ' btn-block'
    }
    if (fullheight) {
        classes += ' btn-fullheight'
    }
    if (size) {
        classes += ` btn-${size}`
    }
    if (active) {
        classes += ' active'
    }
    if (disabled) {
        classes += ' disabled'
    }
    if (className) {
        classes += ' ' + className
    }

    return (
        <AnyLink to={to} className={classes}>
            {children}
        </AnyLink>
    )
}

AnyLinkButton.defaultProps = {
    disabled: false,
    size: undefined,
    block: false,
    upperCase: true,
}

export default AnyLinkButton

type TReactButtonProps = Omit<React.HTMLProps<HTMLButtonElement>, 'size'>

interface BtnProps extends Omit<ButtonProps & TReactButtonProps, 'type' | 'href'> {
    to: string
    pdftColor?: string
    upperCase?: boolean
    children: React.ReactNode
}
