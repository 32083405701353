import React from 'react'
import { Alert, Col, Form, Row, Spinner } from 'react-bootstrap'
import Select from 'react-select'
import { IFilterOption } from '../../../types/Common'
import Translation from '../../../types/Translation'

import ColoredBox from '../../content/ColoredBox'
import GenericForm from '../../content/form/GenericForm'
import withTranslations from '../../hoc/withTranslations'
import * as styles from './RegistrationForm.module.scss'

class RegistrationForm extends React.Component<RegistrationFormProps, RegistrationFormState> {
    constructor(props: RegistrationFormProps) {
        super(props)

        this.state = {
            form: {
                email: '',
                hasgender: false, // work around to have no gender in form
                firstname: '',
                lastname: '',
                language: this.props.defaultLangOpt,
                customer: {
                    name: '',
                    country: this.props.defaultCountryOpt,
                },
                newsletter: false,
                updateFlash: false,
            },
            spinner: true,
            errorCountry: false,
        }
    }

    async componentDidMount() {
        if (this.props.onComponentMount) {
            await this.props.onComponentMount(this)
        }
    }

    handleSubmit = async () => {
        if (!this.props.disableSpinner) {
            this.setState({
                spinner: true,
            })
        }

        const hasSelected = this.state.form.customer.country === undefined ? false : true
        this.showFormError(!hasSelected)
        if (this.props.onSubmit && hasSelected) {
            await this.props.onSubmit(this.state)
        }

        if (!this.props.disableSpinner) {
            this.setState({
                spinner: false,
            })
        }
    }

    showFormError(errorCountry: boolean) {
        const newState = {
            errorCountry,
        }
        this.setState(newState)
    }

    handleChange = (e: any) => {
        const field = e.target.name
        const newState = {
            form: {
                ...this.state.form,
                [field]: e.target.value,
            },
        }

        this.setState(newState)
    }

    handleCustomerChange = (e: any) => {
        const field = e.target.name
        const newState = {
            form: {
                ...this.state.form,
                customer: {
                    ...this.state.form.customer,
                    [field]: e.target.value,
                },
            },
        }

        this.setState(newState)
    }

    handleLanguageChange = (opt: any) => {
        this.setState({
            form: {
                ...this.state.form,
                language: opt,
            },
        })
    }

    handleCountryChange = (opt: any) => {
        this.setState({
            form: {
                ...this.state.form,
                customer: {
                    ...this.state.form.customer,
                    country: opt,
                },
            },
            errorCountry: false,
        })
    }

    handleCheckboxChange = (e: any) => {
        const field = e.target.name
        const checked = e.target.checked

        this.setState({
            form: {
                ...this.state.form,
                [field]: checked,
            },
        })
    }

    render() {
        const { t, countryOptions, children } = this.props

        const maxLengthName = 40
        const maxLengthEmail = 254

        const { country } = this.state.form.customer
        const { errorCountry } = this.state
        const { email, firstname, lastname, language, newsletter, updateFlash } = this.state.form

        const {
            errorMsg,
            successMsg,
            languageOptions,
            emailAddrInfo,
            submitBtnText,
            additionalInfo,
            disableSpinner,
            displayUpdateFlash,
        } = this.props

        let colorBoxContent = (
            <div className={styles.spinnerContainer}>
                <Spinner animation={'border'} />
            </div>
        )

        if (!this.state.spinner || disableSpinner) {
            colorBoxContent = (
                <Row>
                    <Col sm={6}>
                        <Form.Group controlId={'form.email'}>
                            <Form.Label>
                                {t('form', 'general.field.companyemail.label')}

                                <span>*</span>
                            </Form.Label>
                            <Form.Control
                                type="email"
                                name={'email'}
                                required={true}
                                onChange={this.handleChange}
                                value={email}
                                maxLength={maxLengthEmail}
                                autoComplete="username"
                            />
                            <Form.Control.Feedback type="invalid">
                                {t('form', 'form.validate.email.invalid')}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm={6} className={styles.emailInfo}>
                        <p>{emailAddrInfo}</p>
                    </Col>
                    <Col sm={6}>
                        <Form.Group controlId={'form.firstname'}>
                            <Form.Label>
                                {t('form', 'general.field.firstname.label')}
                                <span>*</span>
                            </Form.Label>
                            <Form.Control
                                type={'text'}
                                name={'firstname'}
                                required={true}
                                onChange={this.handleChange}
                                value={firstname}
                                maxLength={maxLengthName}
                                autoComplete="given-name"
                            />
                            <Form.Control.Feedback type="invalid">
                                {t('form', 'form.validate.firstname.invalid')}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group controlId={'form.lastname'}>
                            <Form.Label>
                                {t('form', 'general.field.lastname.label')}
                                <span>*</span>
                            </Form.Label>
                            <Form.Control
                                type={'text'}
                                name={'lastname'}
                                required={true}
                                onChange={this.handleChange}
                                value={lastname}
                                maxLength={maxLengthName}
                                autoComplete="family-name"
                            />
                            <Form.Control.Feedback type="invalid">
                                {t('form', 'form.validate.lastname.invalid')}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group controlId={'form.country'}>
                            <Form.Label>
                                {t('form', 'general.field.country.label')}
                                <span>*</span>
                            </Form.Label>
                            <Select
                                value={country}
                                onChange={this.handleCountryChange}
                                options={countryOptions}
                                classNamePrefix={'pdft_select'}
                            />
                            {errorCountry && <p className="invalid pt-0">{t('form', 'form.validate.country.invalid')}</p>}
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group controlId={'form.language'}>
                            <Form.Label>
                                {t('form', 'general.field.language.label')}
                                <span>*</span>
                            </Form.Label>
                            <Select
                                value={language}
                                onChange={this.handleLanguageChange}
                                options={languageOptions}
                                classNamePrefix={'pdft_select'}
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Row>
                            <Col sm={6}>
                                <Form.Group controlId={'formNewsletter'}>
                                    <Form.Check
                                        type={'checkbox'}
                                        label={'Newsletter'}
                                        name="newsletter"
                                        inline={true}
                                        checked={newsletter}
                                        onChange={this.handleCheckboxChange}
                                    />
                                </Form.Group>
                            </Col>
                            {displayUpdateFlash && (
                                <Col sm={6}>
                                    <Form.Group controlId={'formUpdateFlash'}>
                                        <Form.Check
                                            type={'checkbox'}
                                            label={'Release Newsflash'}
                                            name="updateFlash"
                                            inline={true}
                                            checked={updateFlash}
                                            onChange={this.handleCheckboxChange}
                                        />
                                    </Form.Group>
                                </Col>
                            )}
                        </Row>
                    </Col>
                    <Col sm={6} />
                    {children && children}
                    <Col sm={12}>{additionalInfo}</Col>
                    <Col sm={6} style={{ marginTop: '20px' }}>
                        <Form.Group>
                            <input type={'submit'} className={'btn btn-primary'} value={submitBtnText} />
                        </Form.Group>
                    </Col>
                </Row>
            )
        }

        return (
            <>
                {successMsg && <Alert variant={'success'}>{successMsg}</Alert>}

                {errorMsg && <Alert variant={'danger'}>{errorMsg}</Alert>}
                <GenericForm onSubmit={this.handleSubmit}>
                    <ColoredBox>
                        <div className={styles.coloredBox}>{colorBoxContent}</div>
                    </ColoredBox>
                </GenericForm>
            </>
        )
    }
}

export default withTranslations(RegistrationForm)

interface RegistrationFormProps extends Translation {
    onSubmit: any
    countryOptions: IFilterOption[]
    languageOptions: IFilterOption[]
    onComponentMount?: any
    errorMsg?: string
    successMsg?: string
    emailAddrInfo: string
    submitBtnText: string
    additionalInfo?: JSX.Element
    disableSpinner?: boolean
    defaultLangOpt: IFilterOption
    defaultCountryOpt: IFilterOption
    displayUpdateFlash?: boolean
}

interface ICustomer {
    name: string
    country: IFilterOption
}

interface RegistrationFormState {
    form: {
        email: string
        hasgender: boolean
        firstname: string
        lastname: string
        language: IFilterOption
        customer: ICustomer
        newsletter: boolean
        updateFlash: boolean
    }
    spinner: boolean
    errorCountry: boolean
}
