import * as React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import * as styles from './ColoredBox.module.scss'

const ColoredBox: React.FunctionComponent<ColoredBoxProps> = (props: ColoredBoxProps) => {
    const {
        fullWidth,
        children,
        pdftColor,
        invertColor,
        rounded,
        spaceBottom,
        padding,
        positionStatic,
        noWidth,
        className,
    } = props

    let classes = 'color-box'

    if (pdftColor) {
        classes += ' ' + pdftColor
    }
    if (invertColor) {
        classes += ' inverted'
    }
    if (spaceBottom) {
        classes += ' mb-5'
    }
    if (!noWidth) {
        classes += ' ' + styles.width
    }
    if (rounded) {
        classes += ' rounded'
    }
    if (padding) {
        classes += ' padded'
    }
    if (className) {
        classes += ' ' + className
    }

    if (fullWidth) {
        classes += ' full-width'
        return (
            <div className={classes}>
                <Container className="my-0">
                    <Row>
                        <Col sm={12} className={positionStatic ? styles.static : ''}>
                            {children}
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }

    return <div className={classes}>{children}</div>
}

interface ColoredBoxProps {
    fullWidth?: boolean
    pdftColor?: string
    invertColor?: boolean
    rounded?: boolean
    spaceBottom?: boolean
    padding?: boolean
    positionStatic?: boolean
    noWidth?: boolean
    className?: string
    children: React.ReactNode
}

ColoredBox.defaultProps = {
    pdftColor: 'blue',
    padding: true,
}

export default ColoredBox
