import * as React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import Translation from '../../../types/Translation'
import withTranslations from '../../hoc/withTranslations'
import ContentButton from '../ContentButton'
import * as styles from './LicenseKey.module.scss'

class LicenseKey extends React.Component<LicenseKeyProps, LicenseKeyState> {
    state = {
        copied: false,
    }

    handleCopy = () => {
        this.setState({
            copied: true,
        })
    }

    handleFocus = (event: any) => {
        event.target.select()
    }

    handleAnimationEnd = (e: any) => {
        this.setState({
            copied: false,
        })
    }

    render() {
        const { copied } = this.state
        const { t } = this.props

        return (
            <td colSpan={5} className={styles.keyTd}>
                <input
                    type="text"
                    size={60}
                    value={this.props.licenseKey}
                    onFocus={this.handleFocus}
                    className={styles.key}
                    readOnly
                />

                <CopyToClipboard text={this.props.licenseKey} onCopy={this.handleCopy}>
                    <ContentButton
                        className={`ml-1 my-0 ${styles.copyBtn} ${copied ? styles.copied : ''}`}
                        size="sm"
                        pdftColor="blue"
                        onAnimationEnd={this.handleAnimationEnd}
                    >
                        <i className={'pti-copy copy-icon-bigger'} />
                        {t('template', 'general.btn.copy')}
                    </ContentButton>
                </CopyToClipboard>
            </td>
        )
    }
}

export default withTranslations(LicenseKey)

interface LicenseKeyProps extends Translation {
    licenseKey: string
}

interface LicenseKeyState {
    copied: boolean
}
