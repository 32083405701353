import { Link } from 'gatsby'
import * as React from 'react'
import * as styles from './MenuDropdown.module.scss'
export default class MenuDropdown extends React.Component<DropdownProps, DropdownState> {
    state: DropdownState = {
        open: false,
    }

    myRef: any = React.createRef()

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside)
    }

    handleClickOutside = (e: MouseEvent) => {
        if (e.button === 0) {
            if (!this.myRef.current.contains(e.target)) {
                if (this.state.open) {
                    this.setState({ open: false })
                    this.props.onDropdownChange()
                }
            }
        }
    }

    handleClickInside = (event: any) => {
        const classList = event.target.classList

        if (classList.contains('ignore-click')) {
            this.props.hide(this.myRef.current)
            return
        }

        this.props.onDropdownChange(this.myRef.current)
        this.setState({ open: !this.state.open })
    }

    onHover = () => {
        this.props.onHover(this.myRef.current)
    }

    handleDropdownToggleClick = (e: any) => {
        e.preventDefault()
    }

    render() {
        const { title, children, menuEntryLink } = this.props
        const { open } = this.state

        const classes = `dropdown ${open ? 'dropdown-show' : ''}`

        return (
            <li
                ref={this.myRef}
                onClick={this.handleClickInside}
                className={classes}
                onMouseEnter={this.onHover}
                onMouseLeave={this.onHover}
            >
                <Link
                    to={menuEntryLink}
                    className={styles.dropdownToggle}
                    onClick={this.handleDropdownToggleClick}
                    partiallyActive={true}
                    activeClassName={styles.activeMenu}
                >
                    {title}
                </Link>
                <div className={'dropdown-menu'}>{children}</div>
            </li>
        )
    }
}

interface DropdownProps {
    title: string
    onHover: any
    onDropdownChange: any
    menuEntryLink: string
    hide: any
}

interface DropdownState {
    open: boolean
}
