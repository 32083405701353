export const GTM_DATALAYER_NAME = 'dataLayer'

/**
 * Reports an Event to Google Tag Manager (GTM)
 *
 * @param eventName GTM event name
 * @param action GTM action name
 * @param data additional metadata for GTM
 */
export const reportGtmEvent = (eventName: string, action: string, data: TGtmEventData = {}): void => {
    if (typeof window !== 'undefined') {
        // check if code is executed in browser
        const dataLayer = window[GTM_DATALAYER_NAME] || []

        dataLayer.push({
            event: eventName,
            action,
            ...data,
        })

        console.debug('GTM Event', { eventName, action, data }, 'was added to the dataLayer:', dataLayer)
    } else {
        console.warn("GTM Events aren't supported here")
    }
}

type TGtmEventData = Record<string, string>

type TGtmEvent = { event: string; action: string } & TGtmEventData

declare global {
    interface Window {
        dataLayer: TGtmEvent[] // property name must be identical with the value of GTM_DATALAYER_NAME
    }
}
