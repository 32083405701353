import { Link } from 'gatsby'
import * as React from 'react'
import PageContext from '../../../types/PageContext'
import * as styles from './MobileNavigationControls.module.scss'

export default class MobileNavigationControls extends React.Component<
    MobileNavigationControlsProps,
    MobileNavigationControlsState
> {
    handleMenuControlClick = () => {
        if (this.props.onMenuControlClick) {
            this.props.onMenuControlClick()
        }
    }

    public render() {
        const { mobileCollapsed, pageContext } = this.props
        const { headLinks } = pageContext

        let searchLink = ''

        // for pages in pages folder, headLinks does not exist
        if (headLinks && headLinks.search) {
            searchLink = headLinks.search[pageContext.language]
        }

        return (
            <div className={`${styles.mobileControl} d-md-none`}>
                <Link to={searchLink} className={`${styles.btnSearch} pti-search`} />
                <button
                    className={`${styles.toggleNavi} ${mobileCollapsed ? styles.collapsed : ''}`}
                    onClick={this.handleMenuControlClick}
                >
                    <span className={`${styles.iconBar} ${styles.topBar}`} />
                    <span className={`${styles.iconBar} ${styles.middleBar}`} />
                    <span className={`${styles.iconBar} ${styles.bottomBar}`} />
                </button>
            </div>
        )
    }
}

interface MobileNavigationControlsProps {
    mobileCollapsed: boolean
    onMenuControlClick: any
    pageContext: PageContext<any>
}

interface MobileNavigationControlsState {}
