import * as React from 'react'
import { Table } from 'react-bootstrap'
import { ILicenseTableData } from '../../../types/Table'
import Translation from '../../../types/Translation'
import withTranslations from '../../hoc/withTranslations'
import ContentButton from '../ContentButton'
import * as styles from './LicenseTable.module.scss'

class LicenseTable extends React.Component<LicenseTableProps, LicenseTableState> {
    render() {
        const { t } = this.props
        let rows = this.props.tableData.rows.map((row, i: number) => {
            if (!row) {
                return null
            }

            let appendTd: any = (
                <td style={{ width: '15%' }}>
                    <ContentButton
                        pdftColor="green"
                        size="sm"
                        block={true}
                        onClick={row.clickHandler}
                        className="my-0 text-nowrap"
                    >
                        <span className="pti-key" />
                        {t('template', 'licence.table.head.licence')}
                    </ContentButton>
                </td>
            )

            if (row.keyRow) {
                appendTd = null
            }

            return (
                <tr key={`tr-${i}`}>
                    {row.data}
                    {appendTd}
                </tr>
            )
        })

        if (rows.length === 0) {
            rows = [
                <tr key={'empty-license'}>
                    <td> {t('template', 'licenses.info.message.emptylicense')} </td>
                    <td />
                    <td />
                    <td />
                </tr>,
            ]
        }

        const { heads } = this.props.tableData

        return (
            <Table striped={true} className={styles.licenseTable} responsive={true}>
                <thead>
                    <tr>{heads}</tr>
                </thead>
                <tbody>{rows}</tbody>
            </Table>
        )
    }
}

export default withTranslations(LicenseTable)

interface LicenseTableProps extends Translation {
    tableData: ILicenseTableData
}

interface LicenseTableState {}
