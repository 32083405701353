import * as React from 'react'
import MobileNavigation from '../components/layout/mobile_navigation/MobileNavigation'
import Breadcrumbs from '../components/layout/navigation/Breadcrumbs'
import Footer from '../components/template/Footer'
import Header from '../components/template/Header'
import MainContent from '../components/template/MainContent'
import PageContext from '../types/PageContext'
import * as styles from './index.module.scss'
import { renderMetaTags } from './MetaHead'

class MainLayout extends React.Component<MainLayoutProps, MainLayoutState> {
    state: MainLayoutState = {
        mobileCollapsed: true,
    }

    toggleMenuVisibility = () => {
        document.getElementById('navigation_mobile').classList.toggle('mobile-navigation-active')
        document.getElementById('mainWrapper').classList.toggle('wrapper-mobile')

        this.setState({ mobileCollapsed: !this.state.mobileCollapsed })
    }

    render() {
        const { pageContext, children, banner } = this.props

        return (
            <div className={styles.layout}>
                {renderMetaTags(pageContext.meta, pageContext.language)}

                <div id="mainWrapper" className={`${styles.wrapper} full-width`}>
                    <Header
                        pageContext={pageContext}
                        mobileCollapsed={this.state.mobileCollapsed}
                        toggleMenuVisibility={this.toggleMenuVisibility}
                    />
                    {banner}
                    <Breadcrumbs pageContext={pageContext} />
                    <MainContent>{children}</MainContent>
                    <Footer footerNavigation={pageContext.navigation?.footer} />
                    <MobileNavigation
                        pageContext={pageContext}
                        toggleMenuVisibility={this.toggleMenuVisibility}
                        mobileCollapsed={this.state.mobileCollapsed}
                    />
                </div>
            </div>
        )
    }
}

export default MainLayout

interface MainLayoutProps {
    pageContext: PageContext<any>
    children: React.ReactNode
    banner?: React.ReactNode
}

interface MainLayoutState {
    mobileCollapsed: boolean
}
