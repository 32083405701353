import jwt_decode from 'jwt-decode'

export const isBrowser = () => typeof window !== 'undefined'

const authCookieName = 'pdftAuth'

export const handleLogin = (token, userFullname) => {
    let decodedToken

    try {
        decodedToken = jwt_decode(token)
    } catch (e) {
        return false
    }

    const expiresDate = new Date(decodedToken.exp * 1000)
    saveAuthCookie(token, userFullname, expiresDate)

    return true
}

const setCookie = (name, value, expiresDate) => {
    const expires = '; expires=' + expiresDate.toUTCString()
    if (typeof window !== 'undefined') {
        window.document.cookie = name + '=' + (value || '') + expires + '; path=/'
    }
}

const getCookie = (name) => {
    const nameEQ = name + '='

    if (typeof window !== 'undefined') {
        const ca = window.document.cookie.split(';')

        for (let c of ca) {
            while (c.charAt(0) === ' ') {
                c = c.substring(1, c.length)
            }
            if (c.indexOf(nameEQ) === 0) {
                return c.substring(nameEQ.length, c.length)
            }
        }
    }

    return null
}

export const isLoggedIn = () => !!getCookie(authCookieName)

export const getToken = () => {
    const authCookie = parseAuthCookie()
    return authCookie.token
}

export const getUserFullname = () => {
    const name = parseAuthCookie()?.userFullname
    return name === 'null'? null : name
}

const saveAuthCookie = (token, userFullname, expiresDate) => {
    const cookieValue = `token|${token}::name|${userFullname}`
    setCookie(authCookieName, cookieValue, expiresDate)
}

const parseAuthCookie = () => {
    const authCookie = getCookie(authCookieName)
    if (authCookie) {
        const [tokenPart, userFullnamePart] = authCookie.split('::')

        const token = tokenPart.split('|')[1]
        const userFullname = userFullnamePart.split('|')[1]

        return {
            token,
            userFullname,
        }
    }
}

const eraseCookie = (name) => {
    if (typeof window !== 'undefined') {
        window.document.cookie = name + '=; Max-Age=-99999999; path=/'
    }
}

export const logout = () => {
    eraseCookie(authCookieName)
}
