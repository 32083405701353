import * as React from 'react'
import { Col, Row, Table, Image } from 'react-bootstrap'
import withTranslations from '../components/hoc/withTranslations'
import PageContext from '../types/PageContext'
import Translation from '../types/Translation'
import ConfirmationImg from '../images/robo_confirmation.jpg'
import ContentButton from '../components/content/ContentButton'

const SuccessPageTemplate: React.FunctionComponent<SuccessPageTemplateProps> = (props: SuccessPageTemplateProps) => {
    const { formData, formType, t, pageContext } = props
    const { headLinks } = pageContext

    let contactLink = ''

    if (headLinks) {
        contactLink = headLinks.contact?.[pageContext.language] || ''
    }
    const dataRows = []

    if (formData) {
        for (const key of Object.keys(formData)) {
            if (formData[key]) {
                const newDataRow = (
                    <tr key={`data-${key}`}>
                        <td>{key}</td>
                        <td>{formData[key]}</td>
                    </tr>
                )
                dataRows.push(newDataRow)
            }
        }
    }

    return (
        <>
            {formType && formType === 'evalfeedback' && (
                <Row>
                    <Col sm={4}>
                        <Image src={ConfirmationImg} width={'100%'} />
                    </Col>

                    <Col>
                        <Row>
                            <Col>
                                <div dangerouslySetInnerHTML={{ __html: t('template', 'page.success.message.feedback') }} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <ContentButton variant="primary" to={contactLink}>
                                    {t('template', 'product.button.contact.label')}
                                </ContentButton>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}
            {(!formType || formType !== 'evalfeedback') && (
                <Row>
                    <Col>
                        <div dangerouslySetInnerHTML={{ __html: t('template', 'page.success.message.success') }} />

                        <Table className={'table table-hover'} striped={true} bordered={true} hover={true}>
                            <tbody>{dataRows}</tbody>
                        </Table>
                    </Col>
                </Row>
            )}
        </>
    )
}

interface SuccessPageTemplateProps extends Translation {
    formData: any
    pageContext: PageContext<any>
    formType?: string
}

export default withTranslations(SuccessPageTemplate)
