import React from 'react'
import { Form } from 'react-bootstrap'
import * as styles from './GenericForm.module.scss'

export default class GenericForm extends React.Component<GenericFormProps, GenericFormState> {
    state = {
        validated: false,
    }

    handleSubmit = (event: any) => {
        const form = event.currentTarget
        const formValid = form.checkValidity()

        event.preventDefault()
        event.stopPropagation()

        if (formValid && this.props.onSubmit) {
            this.props.onSubmit()
        }

        this.setState({ validated: true })
    }

    render() {
        const { children } = this.props
        const { validated } = this.state

        return (
            <Form className={styles.form} noValidate={true} onSubmit={this.handleSubmit} validated={validated}>
                {children}
            </Form>
        )
    }
}

interface GenericFormProps {
    onSubmit: any
}

interface GenericFormState {
    validated: boolean
}
