import * as React from 'react'
import Helmet from 'react-helmet'
import { IAlternateUrl, IMeta } from '../types/Meta'

export const renderMetaTags = (meta: IMeta, language: string) => {
    const alternateLinks: JSX.Element[] = []

    meta.alternateUrls.forEach((url) => {
        alternateLinks.push(<link key={`altUrl_${url.lang}`} href={url.url} rel={'alternate'} hrefLang={url.lang} />)
    })

    return (
        <Helmet>
            <link
                rel="stylesheet"
                type="text/css"
                href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,700"
                media="all"
            />
            <title>{meta.pageTitle}</title>

            <meta name={'title'} content={meta.metaTitle} />

            <meta property={'og:title'} content={meta.metaTitle} />

            <meta name={'twitter:title'} content={meta.metaTitle} />

            <link href={meta.canonical} rel={'canonical'} />
            <meta property={'og:url'} content={meta.canonical} />

            {alternateLinks}

            <meta name={'description'} content={meta.description} />

            <meta property={'og:description'} content={meta.description} />

            <meta name={'twitter:description'} content={meta.description} />

            <meta name={'author'} content={meta.author} />

            <meta property={'og:type'} content={meta.type} />

            <meta property={'og:site_name'} content={meta.siteName} />

            <html lang={language} />
        </Helmet>
    )
}
