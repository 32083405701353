import * as React from 'react'
import { GatsbyLinkProps, Link as GatsbyLink } from 'gatsby'

/**
 * The AnyLink Component provides support for any type of possible links (eg. internal GatsbyLink, external or mailto)
 * The type of the destination (property 'to') is determined by its content according to the following following rules:
 * 1. starting with `http` or `//` => external link (no gatsby link)
 * 2. starting with `/public`      => external link (no gatsby link)
 * 3. starting with `#`            => internal link (no gatsby link)
 * 4. starting with `mailto:`      => mailto link (no gatsby link)
 * 5. starting with `download:`    => download link (no gatsby link)
 * 6. starting with `@`            => lookup failed; displayed as text only
 * 7. other values                 => internal link (gatsby link)
 *
 * @param props
 * @returns
 */
const AnyLink: React.FunctionComponent<AnyLinkProps> = (props: AnyLinkProps) => {
    const { to, children, hasIcon, ...linkProps } = props

    if (to.startsWith('http') || to.startsWith('//') || to.startsWith('/public')) {
        // external link: default target is _blank
        return (
            <a
                href={to}
                title={props.title}
                target={props.target || '_blank'}
                className={props.className + (hasIcon ? (to.startsWith('/public') ? ' link-file' : ' link-external') : '')} // TODO icon class
                style={props.style}
                hrefLang={props.hrefLang}
            >
                {children}
            </a>
        )
    } else if (to.startsWith('#')) {
        return (
            <a href={to} title={props.title} className={props.className} style={props.style}>
                {children}
            </a>
        )
    } else if (to.startsWith('mailto:') || to.startsWith('tel:')) {
        return (
            <a href="#" className={props.className} style={props.style} onClick={openLink(to, '_self')}>
                {children}
            </a>
        )
    } else if (to.startsWith('download:')) {
        const downloadUrl = to.substring(9) // 9 = prefix length of "download:"
        return (
            <a
                href={downloadUrl}
                title={props.title}
                target={props.target || '_blank'}
                className={props.className + (hasIcon ? ' pti-download' : '')}
                style={props.style}
                hrefLang={props.hrefLang}
                download
            >
                {children}
            </a>
        )
    } else if (to.startsWith('@')) {
        return <span className="invalid-url">{children}</span>
    }

    return (
        <GatsbyLink to={to} className={hasIcon ? 'link-more' : ''} {...linkProps}>
            {children}
        </GatsbyLink>
    )
}

const openLink = (url: string, target?: string): React.MouseEventHandler<HTMLAnchorElement> => (e) => {
    e?.preventDefault()
    window.open(url, target)
}

export default AnyLink

interface AnyLinkProps extends Omit<GatsbyLinkProps<Record<string, unknown>>, 'ref' | 'to' | 'href'> {
    to: string
    children: React.ReactNode
    hasIcon?: boolean
}
